//Logo
import logo from '../assets/logo.png';

//About
import about from '../assets/about.jpg';

//Contact
import email from '../assets/email.png';
import mobile from '../assets/mobile.png';

const images = {
  logo,
  about,
  email,
  mobile,
};

export default images;
